/* eslint-disable array-callback-return */
/* eslint-disable no-restricted-syntax */
// import { fetchAllteams } from './teamActions';
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
    error: null,
    teams: [],
    selectedTeam: null,
    selfTeam: null,
    loading: false,
    teamMembers: [],
    rolePermissions: [],
    myPermissions: [],
    selectedProjectPermissions: [],
    updateTeamMemberProjectsRoleLoading: false
};
const setLocal = ({ key = 'br-selected-team', value }) => localStorage.setItem(key, value);
const getLocal = (key = 'br-selected-team') => localStorage.getItem(key);
const teamSlice = createSlice({
    name: 'team',
    initialState,
    reducers: {
        hasError(state, action) {
            state.error = action.payload;
        },
        clearError(state) {
            state.error = null;
        },
        teamInit(state) {
            Object.keys(initialState).map((k) => {
                state[k] = initialState[k];
            });
        },
        teams(state, { payload }) {
            const { items = [], userId, selectCollaborationTeam } = payload;

            const selfTeam = items.find((item) => item.userId === userId);
            state.selfTeam = selfTeam;
            let selectedTeam = null;
            const localId = getLocal();
            if (localId) {
                if (selectCollaborationTeam && items?.length > 1) {
                    selectedTeam = items.find((item) => item?.userId !== userId);

                    setLocal({ value: selectedTeam._id });
                } else {
                    selectedTeam = items.find((item) => item._id === localId);
                    setLocal({ value: selectedTeam._id });
                }
            } else if (selectCollaborationTeam && items?.length > 1) {
                selectedTeam = items.find((item) => item?.userId !== userId);
                setLocal({ value: selectedTeam._id });
            } else {
                console.log({ selfTeam });
                selectedTeam = selfTeam;
                setLocal({ value: selfTeam._id });
            }
            if (state.rolePermissions?.length) {
                console.log(state.rolePermissions, 'state.rolePermissions?.length');
                const selfPermissions = items?.find?.((item) => item.name === 'owner')?.permissions || [];
                const selectedTeam = items.find((item) => item._id === localId);
                if (selectedTeam) {
                    const rolePermissionId = selectedTeam?.teamMember?.projectWiseRolePermission?.[0]?.rolePermissionId;
                    if (rolePermissionId) {
                        state.myPermissions = items?.find?.((item) => item._id === rolePermissionId)?.permissions || [];
                    } else {
                        state.myPermissions = selfPermissions;
                    }
                } else {
                    state.myPermissions = selfPermissions;
                }
            }
            state.selectedTeam = selectedTeam;
            state.teams = items;
            state.loading = false;
        },
        teamsMain(state, { payload }) {
            const { items = [], userId } = payload;
            state.teams = items;
            const selfTeam = items.find((item) => item.userId === userId);
            state.selfTeam = selfTeam;
            let selectedTeam = null;
            const localId = getLocal();

            // console.log('teamssssssssssssssssssssssssssssssssss', new Date());
            if (localId) {
                selectedTeam = items.find((item) => item._id === localId);
                setLocal({ value: selectedTeam._id });
            } else {
                selectedTeam = selfTeam;
                setLocal({ value: selfTeam._id });
            }
            if (state.rolePermissions?.length) {
                console.log(state.rolePermissions, 'state.rolePermissions?.length');
                const selfPermissions = items?.find?.((item) => item.name === 'owner')?.permissions || [];
                const selectedTeam = items.find((item) => item._id === localId);
                if (selectedTeam) {
                    const rolePermissionId = selectedTeam?.teamMember?.projectWiseRolePermission?.[0]?.rolePermissionId;
                    if (rolePermissionId) {
                        state.myPermissions = items?.find?.((item) => item._id === rolePermissionId)?.permissions || [];
                    } else {
                        state.myPermissions = selfPermissions;
                    }
                } else {
                    state.myPermissions = selfPermissions;
                }
            }
            state.selectedTeam = selectedTeam;
            state.loading = false;
        },
        initialPermission(s, { payload }) {
            s.myPermissions = payload;
        },
        updateTeam(state, { payload }) {
            const { item = {} } = payload;
            const selfTeam = { ...state.selfTeam, ...item };
            state.selfTeam = selfTeam;
            state.teams = state.teams.map((im) => {
                if (selfTeam?._id === im?._id) {
                    return { ...im, ...item };
                }
                return im;
            });
            // const selfTeam = items.find((item) => item.userId === userId);
            if (selfTeam?._id === state?.selectedTeam?._id) {
                state.selectedTeam = { ...state.selectedTeam, ...item };
            }
        },
        loading(state, action) {
            state.loading = action.payload ?? false;
        },
        changeTeamSelection(state, { payload }) {
            const selectedTeam = state.teams.find((t) => t._id === payload);
            setLocal({ value: selectedTeam?._id || '' });
            state.selectedTeam = selectedTeam;
            if (payload === state?.selfTeam?._id) {
                state.myPermissions = state.rolePermissions.find((item) => item.name === 'owner').permissions;
            } else {
                /**
                 * {"_id":"","userId":"","teamId":"","projectWiseRolePermission":[{"_id":"","projectId":"","rolePermissionId":""}]}
                 */
                const projectWiseRolePermission = selectedTeam?.teamMember?.projectWiseRolePermission;
                // console.log(JSON.stringify(selectedTeam?.teamMember?.projectWiseRolePermission));
                if (!projectWiseRolePermission) return;
                const [{ rolePermissionId }] = projectWiseRolePermission;
                state.myPermissions = state.rolePermissions.find((item) => item._id === rolePermissionId).permissions || [];
            }
        },
        rolePermissions(state, { payload }) {
            const { items = [] } = payload;
            state.rolePermissions = items;
            console.log(state.rolePermissions, ' state.rolePermissions');
            // let myPermissions = [];
            // const selfPermissions = items?.find?.((item) => item.name === 'owner')?.permissions || [];
            // const localId = getLocal();
            // if (localId) {
            //     const selectedRP = state.selectedTeam;
            //     // console.log('rolePermissionssssssssssssssssssssssssssssssssss', new Date(), localId, items, selfPermissions);
            //     if (selectedRP) {
            //         const rolePermissionId = selectedRP?.teamMember?.projectWiseRolePermission?.[0]?.rolePermissionId;
            //         if (rolePermissionId) {
            //             state.myPermissions = items?.find?.((item) => item._id === rolePermissionId)?.permissions || [];
            //         } else {
            //             state.myPermissions = selfPermissions;
            //         }
            //     } else {
            //         // myPermissions = selfPermissions;
            //     }
            // } else {
            //     // myPermissions = selfPermissions;
            // }
            // state.myPermissions = myPermissions;
            // state.myPermissions = items?.find((item) => item.name === 'owner')?.permissions || {};
            // state.loading = false;
        },
        // updateTeamMemberProjectsRole(state, { payload }) {
        //     const { rolePermissionId, teamMemberId, rolePermissions = [] } = payload;

        //     state.teamMembers = state.teamMembers.map((item) => {
        //         if (teamMemberId === item._id) {
        //             // const { projectWiseUserRole } = item;
        //             item.projectWiseUserRole = item.projectWiseUserRole.map((im) => {
        //                 im.rolePermission = rolePermissions.find((rp) => rp._id === rolePermissionId);
        //                 return im;
        //             });
        //         }
        //         return item;
        //     });
        //     state.updateTeamMemberProjectsRoleLoading = false;
        // },
        // updateTeamMemberProjectsRoleLoading(state, { payload }) {
        //     state.updateTeamMemberProjectsRoleLoading = payload;
        // },
        updateTeamMemberRole(state, { payload }) {
            console.log(state, payload);
            // const { PURP_ID, rolePermissionId, teamMemberId } = payload;
            // state.teamMembers = state.teamMembers.map((item) => {
            //     if (teamMemberId === item._id) {
            //         const { projectWiseUserRole } = item;
            //     }
            //     return item;
            // });
        }
    }
});

export const { teams, loading, hasError, changeTeamSelection, clearError, rolePermissions, updateTeam, initialPermission } =
    teamSlice.actions;

export default teamSlice.reducer;
